import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParam } from "react-use";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
// import { faCalendarXmark } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
// import { convertBackendDateIntoUTC } from "../../../services/convertBackendDateIntoUTC";
import Tabs from "@mui/joy/Tabs";
import { Modal } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Cookies from "js-cookie";
import debounce from "lodash.debounce";
// import Loader from "../../../components/Loader/Loader";
import TestYourDevice from "../../../components/Modal/TestYourDevice";
import { AppContext } from "../../../AppContext";
import { UnderMaintenanceContext } from "../../../UnderMaintenanceContext";
import BackendDownErrorHandling from "../../Reusables/BackendDownErrorHandling/BackendDownErrorHandling";
// import { Color } from "../../../../GlobalStyles";
import UpcomingBookingCard from "../../ScheduleComponents/UpcomingBookingCard";
import ScrollButton from "../../ScrollButton/ScrollButton";
// import SideNavBar from "../../SideNavBar/SideNavBar";
import SideNavBarStudent from "../../SideNavBar/SideNavBarStudent";
import { SkeletonLoader } from "../../SkeletonLoader/SkeletonLoader";
// import { convertBackendDateIntoUTC } from "../../../services/convertBackendDateIntoUTC";
// import SideNavBar from "../../SideNavBar/SideNavBar";
// import { checkDate } from "../../../services/dateFunctions";
// import TimeService from "../../../services/epochTime";
import Service from "../../../services/httpService";
// import { sort_by } from "../../../services/sortJSONinArray";
import { QUERY_PARAM_AUTH_TOKEN } from "../../../common/constants";
import "../../Students/FeedbackFromStudent/FeedbackFromStudent.css";
import "../../Mentors/UpcomingBooking/UpcomingBooking.css";
const StudentActivity = () => {
  const location = useLocation();
  //loader
  // const [loading, setLoading] = useState(true);
  //objs
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { formInputs, setFormInputs } = useContext(AppContext);
  const { userDetails } = location;
  console.log("StudentActivity ~ formInputs:", {
    formInputs,
    userDetails,
    setFormInputs,
  });
  const { underMaintenanceCalled, setUnderMaintenanceCalled } = useContext(
    UnderMaintenanceContext
  );
  const navigate = useNavigate();
  const state = useLocation();
  const services = new Service(setUnderMaintenanceCalled);
  // const timeService = new TimeService();
  //varaibles
  const [slotData, setSlotData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [responseDataEmpty, setResponseDataEmpty] = useState(false);
  const [settingType, setSettingType] = useState("upcoming");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageRecent, setCurrentPageRecent] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const isUpcomingFetchingRef = useRef(isFetching);
  const [isLoadingUpcoming, setIsLoadingUpcoming] = useState(false);
  const [isLoadingRecent, setIsLoadingRecent] = useState(false);

  console.log("state", state);

  const handleUpcomingBookingsTabClick = async (type, page) => {
    if (currentPage === 1 && type === "upcoming") {
      setIsLoadingUpcoming(true);
    } else if (currentPageRecent === 1 && type === "recent") {
      setIsLoadingRecent(true);
    }
    setSettingType(type);
    try {
      const user_type = Cookies.get("user_type");
      const res = await services.get(
        `scheduling/bookedSlot?user_type=${user_type}&limit=4&page=${page}&type=${type}`
      );
      if (res.data.length) {
        console.log("nbjdvbks", res.data);
        let tempData = res.data.map(booking => ({
          ...booking,
          features: JSON.parse(booking.features),
          student_material: JSON.parse(booking.student_material),
          resume: JSON.parse(booking.resume) || { url: "", name: "" },
          other_docs: JSON.parse(booking.other_docs) || { files: [] },
        }));

        if (type === "upcoming") {
          if (currentPage === 1 || page !== currentPage) {
            setSlotData(prevData => {
              const updatedData = prevData.filter(existingItem => {
                const exists = tempData.some(
                  newItem => newItem.id === existingItem.id
                );
                return exists ? false : true;
              });
              return [...updatedData, ...tempData];
            });
            setCurrentPage(page); // Update the currentPage for upcoming
            if (res.data.length === 0) {
              setIsLoadingUpcoming(false);
            }
          }
        } else if (type === "recent") {
          if (currentPageRecent === 1 || page !== currentPageRecent) {
            setRecentData(prevData => {
              const updatedData = prevData.filter(existingItem => {
                const exists = tempData.some(
                  newItem => newItem.id === existingItem.id
                );
                return exists ? false : true;
              });
              return [...updatedData, ...tempData];
            });
            setCurrentPageRecent(page); // Update the currentPageRecent for recent
            if (res.data.length === 0) {
              setIsLoadingRecent(false);
            }
          }
        }
        isUpcomingFetchingRef.current = false;
      } else {
        setResponseDataEmpty(true);
      }
    } catch (err) {
      console.error(err);
    } finally {
      if (type === "upcoming" && slotData.length === 0) {
        setIsLoadingUpcoming(false);
      } else if (type === "recent" && recentData.length === 0) {
        setIsLoadingRecent(false);
      }
    }
  };
  console.log("slot data", slotData);
  console.log("recent data", recentData);

  const debouncedHandleScroll = debounce(() => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;

    if (
      !responseDataEmpty &&
      windowHeight + scrollTop + 10 >= documentHeight &&
      !isUpcomingFetchingRef.current
    ) {
      setIsFetching(true);
      isUpcomingFetchingRef.current = true;
    }
  }, 1000);

  useEffect(() => {
    window.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
      debouncedHandleScroll.cancel();
    };
  }, [debouncedHandleScroll]);

  useEffect(() => {
    if (isFetching) {
      if (settingType === "upcoming") {
        handleUpcomingBookingsTabClick("upcoming", currentPage + 1);
      } else {
        handleUpcomingBookingsTabClick("recent", currentPageRecent + 1);
      }
      setIsFetching(false);
    }
  }, [isFetching]);

  useEffect(() => {
    // handleUpcomingBookingsTabClick("recent", currentPageRecent);
    sessionStorage.clear();
    handleUpcomingBookingsTabClick("upcoming", currentPage);
    // setFormInputs(userDetails);
  }, []);

  const handleLogout = e => {
    console.log("Logout loader!!1");
    Cookies.remove("token");
    Cookies.remove("user_type");
    console.log("Logout loader!!2");
    navigate("/");
    console.log("Logout loader!!3");
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleTabChange = (event, newValue) => {
    if (
      (newValue === 0 && settingType !== "upcoming") ||
      (newValue === 1 && settingType !== "recent")
    ) {
      if (newValue === 0) {
        setSettingType("upcoming");
        setResponseDataEmpty(false);
      } else {
        setSettingType("recent");
        setResponseDataEmpty(false);
        handleUpcomingBookingsTabClick("recent", currentPageRecent);
      }
    }
  };

  return (
    <div>
      {underMaintenanceCalled ? (
        <BackendDownErrorHandling />
      ) : (
        <div className="upcomingBookingMain" style={{ marginTop: "0px" }}>
          <SideNavBarStudent />
          <div style={{ right: "0px", textAlign: "right" }}>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{ background: "white" }}
            >
              <AccountCircleOutlinedIcon
                style={{ height: "50px", width: "50px", color: "black" }}
              />
            </Button>
            <Menu
              id="account-menu"
              anchorEl={anchorEl}
              open={open}
              sx={{ borderRadius: "16px" }}
              onClose={handleClose}
              PaperProps={{
                elevation: 3,
                sx: {
                  borderRadius: "16px",
                },
              }}
              MenuListProps={{
                sx: {
                  width: "250px",
                  paddingBottom: "2rem",
                },
                "aria-labelledby": "basic-button",
              }}
            >
              <p className="firstNameAndLastNAme">
                {formInputs?.first_name} {formInputs?.last_name}
              </p>
              <p className="firstNameAndLastNAme1">
                {formInputs?.userType === "mentor" ? "Mentor" : "student"}
              </p>
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <button className="logoutButton" onClick={handleLogout}>
                  Logout
                </button>
              </div>
            </Menu>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1.5rem",
            }}
          >
            <p className="upcomingBookingText">Your Bookings</p>
            <button
              className="Rephrasebutton"
              onClick={() => [setShowModal(true)]}
            >
              Test your Device
            </button>
          </div>
          <Tabs
            aria-label="tabs"
            value={settingType === "upcoming" ? 0 : 1} // Set value based on settingType
            sx={{
              bgcolor: "transparent",
              width: "100%",
              marginBottom: "1.5rem",
            }}
            onChange={handleTabChange}
          >
            <TabList
              disableUnderline
              sx={{
                p: 0.5,
                gap: 0.5,
                borderRadius: "xl",
                bgcolor: "background.level1",
                [`& .${tabClasses.root}[aria-selected="true"]`]: {
                  boxShadow: "sm",
                  bgcolor: "background.surface",
                },
              }}
            >
              <Tab sx={{ width: "50%" }} disableIndicator>
                Upcoming Bookings
              </Tab>
              <Tab sx={{ width: "50%" }} disableIndicator>
                Recent Bookings
              </Tab>
            </TabList>
            <TabPanel value={0}>
              {isLoadingUpcoming && (
                <>
                  <div
                    style={{
                      marginBottom: "1.5rem",
                    }}
                  >
                    <SkeletonLoader />
                  </div>
                  <div
                    style={{
                      marginBottom: "1.5rem",
                    }}
                  >
                    <SkeletonLoader />
                  </div>
                  <div
                    style={{
                      marginBottom: "1.5rem",
                    }}
                  >
                    <SkeletonLoader />
                  </div>
                </>
              )}
              {!isLoadingUpcoming && slotData.length === 0 ? (
                <div className="noUpcomingBookings">
                  <div className="noUpcomingBookingsIcon">
                    <EventBusyIcon
                      sx={{
                        color: "#1C8EA8",
                        width: "100px",
                        height: "100px",
                      }}
                    />
                  </div>
                  <div className="noUpcomingBookingsTexts">
                    <h1 className="noUpcomingBookingsText">
                      No Upcoming Bookings
                    </h1>
                    <p
                      className="fontFamilyFixed"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Book an activity through the app <br />
                      to view your bookings here
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  {slotData.map(slot => (
                    <div>
                      <UpcomingBookingCard
                        settingType={settingType}
                        slot={slot}
                        user_type="student"
                        isLoadingUpcoming={isLoadingUpcoming}
                        setIsLoadingUpcoming={setIsLoadingUpcoming}
                      />
                    </div>
                  ))}
                </>
              )}
            </TabPanel>
            <TabPanel value={1}>
              {isLoadingRecent && (
                <>
                  <div
                    style={{
                      marginBottom: "1.5rem",
                    }}
                  >
                    <SkeletonLoader />
                  </div>
                  <div
                    style={{
                      marginBottom: "1.5rem",
                    }}
                  >
                    <SkeletonLoader />
                  </div>
                  <div
                    style={{
                      marginBottom: "1.5rem",
                    }}
                  >
                    <SkeletonLoader />
                  </div>
                </>
              )}
              {!isLoadingRecent && recentData.length === 0 ? (
                <div className="noUpcomingBookings">
                  <div className="noUpcomingBookingsIcon">
                    <EventBusyIcon
                      sx={{
                        color: "#1C8EA8",
                        width: "100px",
                        height: "100px",
                      }}
                    />
                  </div>
                  <div className="noUpcomingBookingsTexts">
                    <h1 className="noUpcomingBookingsText">
                      No Recent Bookings
                    </h1>
                    <p
                      className="fontFamilyFixed"
                      style={{ textAlign: "center" }}
                    >
                      Book an activity through the app <br />
                      to view your bookings here
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  {recentData.map(slot => (
                    <div>
                      <UpcomingBookingCard
                        settingType={settingType}
                        slot={slot}
                        user_type="student"
                        isLoadingRecent={isLoadingRecent}
                        setIsLoadingRecent={setIsLoadingRecent}
                      />
                    </div>
                  ))}
                </>
              )}
            </TabPanel>
          </Tabs>
        </div>
      )}

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        sx={{ zIndex: "1" }}
      >
        <TestYourDevice
          setShowModal={closeModal}
          token={useSearchParam(QUERY_PARAM_AUTH_TOKEN)}
        />
      </Modal>
      <ScrollButton />
    </div>
  );
};

export default StudentActivity;
